.label {
  font-size: 16px;
  color: #19317E;
  font-weight: bold;
  margin-bottom: 10px;
}

.unselectable {
  margin-bottom: 27px;
  border: 1px solid #AAA;
  background-color: #EEE;
  padding: 10px;
  min-height: 40px;
  width: 100%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.selectable{
  margin-bottom: 27px;
  min-height: 40px;
  border: 1px solid #AAA;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  color: #AAA;
}

.input {
  height: 40px;
}
