@import 'variables/colors';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

.text-error {
  color: $red;
}

.input-error {
  border: 1px solid $red;
}
