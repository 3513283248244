.container {
  min-height: 500px;
}

.logo_osde {
  width: 212px;
}

.button_login {
  margin-top: 10px;
  border: none;
  border-radius: 2px;
  padding: 8px 16px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  line-height: normal;
  max-width: 220px;
  min-height: 40px;
  display: inline-block;
}

.logo_google {
  margin-right: 12px;
  width: 20px;
  vertical-align: middle;
}
