.rectangulo {
  height: 16px;
  width: 100%
};

.color-osde {
  background-color: #1226AA;
};

.color-binaria {
  background-color: #004064;
};

.color-on {
  background-color: #3CD19A;
};